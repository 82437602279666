<template>
  <CollectionModalAdd
    v-model:open="open"
    :flowers="includeFlower ? [flower] : []"
    :flower-cultivars="flowerCultivars"
  >
    <div class="rounded-lg border border-grey-400 bg-white max-md:hidden">
      <NuxtPicture
        v-if="flower.image_primary"
        class="block aspect-square overflow-clip rounded-lg bg-secondary-700 object-cover"
        sizes="sm:100vw lg:800px"
        :alt="flower.image_primary?.alt || ''"
        :src="imageUrl(flower.image_primary)"
      />

      <div class="px-6 py-4 text-center font-lato text-xl font-medium">
        {{ flower.title_latin }}
      </div>
    </div>
  </CollectionModalAdd>
</template>

<script setup lang="ts">
import type {Flower, FlowerCultivar} from '~/graphql/graphql';

withDefaults(defineProps<{
  flower: Pick<Flower, 'uuid' | 'image_primary' | 'title' | 'title_latin'>
  flowerCultivars?: Array<Pick<FlowerCultivar, 'uuid' | 'title'>>
  includeFlower?: boolean
}>(), {
  flowerCultivars: () => [],
  includeFlower: true,
});

const open = defineModel<boolean>('open', {
  required: true,
});
</script>
